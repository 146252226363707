import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import Loader from "./Loader";
import { Alert } from "@material-ui/lab";
import TableHeadCell from "./TableHeadCell";

const PF_FIELDS = {
  id: "ID",
  inbound_ip: "Inbound IP",
  inbound_port: "Inbound Port",
  internal_ip: "Internal IP",
  internal_port: "Internal Port",
  source_ip: "Source IP",
  protocol: "Protocol",
  application_protocol: "Application Protocol",
  status: "Status",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const DevicePortForwarding = (props) => {
  const classes = useStyles();
  const [pfRules, setPfRules] = useState(null);
  const deviceSerial = props.deviceSerial || "UNIT12345";
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getPfRules = (deviceSerial) => {
    setLoading(true);
    axios
      .get(`/device/port_forward/get_rules/${deviceSerial}`)
      .then((response) => {
        setPfRules(response.data);
      })
      .catch((error) => {
        setError(error?.response?.data?.detail || "Error fetching rules");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPfRules(deviceSerial);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      window.FreshworksWidget("destroy");
    };
    // eslint-disable-next-line
  }, []);

  const getSummary = (rule) => {
    return (
      <>
        <Typography
          className={classes.heading}
        >{`Inbound: ${rule.inbound_ip}:${rule.inbound_port}`}</Typography>
        <Typography
          className={classes.heading}
        >{`Internal: ${rule.internal_ip}:${rule.inbound_port}`}</Typography>
      </>
    );
  };

  if (loading) return <Loader />;

  return (
    <>
      {error && (
        <Box pt={3}>
          <Alert severity="error">
            {error.split("\n").map((line) => (
              <p>{line}</p>
            ))}
          </Alert>
        </Box>
      )}
      <Box>
        {pfRules?.rules &&
          pfRules?.rules.map((rule) => (
            <Accordion key={rule.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="content"
                id={rule.id}
              >
                {getSummary(rule)}
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="PF table">
                    <TableBody>
                      {Object.keys(PF_FIELDS).map((field) => (
                        <TableRow key={`${rule.id}${field}`}>
                          <TableHeadCell>{PF_FIELDS[field]}</TableHeadCell>
                          <TableCell>{rule[field]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </>
  );
};

export default DevicePortForwarding;
