import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { GREEN, RED, YELLOW } from "../utils/constants";
import moment from "moment";
import RefreshButton from "./RefreshButton";
import NetScanParsedResults from "./NetScanParsedResults";

const NetScanHistory = (props) => {
  const { setError, setLoading, loading } = props;
  const { device_uuid } = useParams();
  const [speedTestResults, setSpeedTestResults] = useState();
  const [tableData, setTableData] = useState([]);

  const getNetScanHistory = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/net-scan-history`)
      .then((response) => {
        if (response.status === 200) {
          setSpeedTestResults(response.data);
        }
      })
      .catch((error) => {
        setError({ text: error.response.data.detail });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNetScanHistory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tableDataToUse = [];

    if (speedTestResults && speedTestResults.cells) {
      for (const speedTestResult of speedTestResults.cells) {
        tableDataToUse.push(speedTestResult);
      }
    }
    setTableData(tableDataToUse);
  }, [speedTestResults]);


  const columns = useRef([
    {
      field: "id",
      headerName: "ID",
      minWidth: 120,
    },
    {
      field: "nsr_result",
      headerName: "Result",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={
              params.row.nsr_result === 2
                ? "SUCCESS"
                : params.row.nsr_result === 0
                ? "In Progress"
                : params.row.nsr_result === 4
                ? "COMMAND ERROR"
                : params.row.nsr_result === 3
                ? "MEMORY ERROR"
                : params.row.nsr_result === 5
                ? "DEVICE TIMEOUT ERROR"
                : params.row.nsr_result === 105
                ? "ISM TIMEOUT ERROR"
                : params.row.nsr_result === 106
                ? "NET MISMATCH ERROR"
                : ""
            }
            size="small"
            style={{
              fontWeight: 500,
              background:
                params.row.nsr_result === 2
                  ? GREEN
                  : params.row.nsr_result === 0
                  ? YELLOW
                  : params.row.nsr_result === 4
                  ? RED
                  : "",
            }}
          />
        );
      },
    },
    {
      field: "nsr_time",
      headerName: "Last Updated At",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Tooltip
            title={moment(params.row.nsr_time * 1000)
              .local()
              .format("lll")}
          >
            <Typography>
              {moment(params.row.nsr_time * 1000)
                .local()
                .fromNow()}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "nsr_text",
      headerName: "Result",
      flex: 1,
      renderCell: (params) => {
        return <NetScanParsedResults text={params.row.nsr_text} />;
      },
    },
  ]);
  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <RefreshButton refreshAction={getNetScanHistory} />
          </Box>
        </Grid>
        <Grid item>
          <DataGrid
            rows={tableData}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            rowCount={tableData.length}
            loading={loading}
            density="compact"
            components={{
              NoRowsOverlay: () => (
                <Box pt={20}>
                  <Grid
                    container
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography>No net scan results.</Typography>
                    </Grid>
                  </Grid>
                </Box>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
NetScanHistory.propTypes = {
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default NetScanHistory;
