import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { NDIAG_STEPS } from "../utils/constants";
import moment from "moment";
import RefreshButton from "./RefreshButton";

const NetDiagResult = (props) => {
  const { setError, setLoading, loading } = props;
  const { device_uuid } = useParams();
  const [speedTestResults, setSpeedTestResults] = useState();
  const [tableData, setTableData] = useState([]);

  const getNetDiagResult = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/net-diag-result`)
      .then((response) => {
        if (response.status === 200) {
          setSpeedTestResults(response.data);
        }
      })
      .catch((error) => {
        setError({ text: error.response.data.detail });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNetDiagResult();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tableDataToUse = [];

    if (speedTestResults && speedTestResults.cells) {
      for (const speedTestResult of speedTestResults.cells) {
        tableDataToUse.push(speedTestResult);
      }
    }
    setTableData(tableDataToUse);
  }, [speedTestResults]);

  const columns = useRef([
    {
      field: "id",
      headerName: "ID",
      minWidth: 120,
    },
    {
      field: "ndr_step",
      headerName: "Step",
      flex: 1,
      valueGetter: (params) => NDIAG_STEPS[params.row.ndr_step],
    },
    {
      field: "ndr_pgid_name",
      headerName: "Port Group Id",
      flex: 1,
    },
    {
      field: "ndr_originalpgid_name",
      headerName: "Original Port Group Id",
      flex: 1,
    },
    {
      field: "ndr_time",
      headerName: "Last Updated At",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Tooltip
            title={moment(params.row.ndr_time * 1000)
              .local()
              .format("lll")}
          >
            <Typography>
              {moment(params.row.ndr_time * 1000)
                .local()
                .fromNow()}
            </Typography>
          </Tooltip>
        );
      },
    },
  ]);
  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Box display="flex" justifyContent="flex-end">
            <RefreshButton refreshAction={getNetDiagResult} />
          </Box>
        </Grid>
        <Grid item>
          <DataGrid
            rows={tableData}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            rowCount={tableData.length}
            loading={loading}
            density="compact"
            components={{
              NoRowsOverlay: () => (
                <Box pt={20}>
                  <Grid
                    container
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item>
                      <Typography>No Net Analysis results.</Typography>
                    </Grid>
                  </Grid>
                </Box>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
NetDiagResult.propTypes = {
  setError: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default NetDiagResult;
