import React, { useState } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Grid,
  Tooltip,
  DialogActions,
  Typography,
} from "@material-ui/core";
import Loader from "./Loader";
import axios from "axios";
import { Cached } from "@material-ui/icons";

const RestartDevice = (props) => {
  const [loading, setLoading] = useState(false);
  const { deviceData, deviceUUID, setMessage, setError } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const restartDevice = () => {
    setLoading(true);
    axios
      .post(`/device/${deviceUUID}/restart`)
      .then(({ data }) => {
        if (data.success) {
          setMessage(data.message);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
        handleClose();
      });
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Grid item>
          <Tooltip title="Restart Device">
            <Button
              onClick={() => setOpen(true)}
              color="secondary"
              variant="contained"
              disabled={!deviceData}
              startIcon={<Cached />}
              style={{ marginRight: "12px" }}
            >
              Restart Device
            </Button>
          </Tooltip>
        </Grid>
        <Dialog
          onClose={handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            Restart Device {deviceData?.name}
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to restart the device with serial no:{" "}
              {deviceData?.serial}?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={restartDevice} color="primary">
              Restart
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default RestartDevice;
