import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useState } from "react";

import {
  Grid,
  Button,
  Dialog,
  Select,
  Tooltip,
  MenuItem,
  TextField,
  InputLabel,
  makeStyles,
  DialogTitle,
  FormControl,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import Loader from "../components/Loader";
import TimerIcon from "@material-ui/icons/Timer";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const scheduleFrequencyOptions = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const scheduleWeeklyOptions = Array.apply(null, Array(7)).map((x, i) => ({
  value: i,
  label: days[i],
}));
const scheduleMonthlyOptions = Array.apply(null, Array(28)).map(
  (x, i) => i + 1
);

export default function DeviceRestartScheduleModal(props) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const { initialData, deviceUUID, setMessage, setError } = props;
  const [open, setOpen] = useState(false);

  const validationSchema = yup.object({
    scheduled_restart_frequency: yup
      .string("Schedule frequency")
      .required("Schedule frequency is required"),
    scheduled_restart_time: yup
      .string("Schedule restart time")
      .required("Schedule time is required"),
    scheduled_restart_day: yup.number("Scheduled restart day").nullable(),
  });

  const scheduleDeviceRestart = (requestData) => {
    setLoading(true);

    axios
      .post(`/device/${deviceUUID}/schedule_restart`, requestData)
      .then((response) => {
        if (response?.data?.success) {
          setMessage(response?.data?.message);
        } else {
          setError(response?.data?.message);
        }
        setOpen(false);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
        formik.setSubmitting(false);
      });
  };

  const removeScheduleDeviceRestart = () => {
    setLoading(true);

    axios
      .post(`/device/${deviceUUID}/remove_schedule_restart`)
      .then((response) => {
        if (response?.data?.success) {
          setMessage(response?.data?.message);
        } else {
          setError(response?.data?.message);
        }
        setOpen(false);
        formik.setValues({});
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
        formik.setSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: initialData || {},
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const requestData = Object.keys(values).reduce((obj, key) => {
        if (
          Object.keys(validationSchema.fields).includes(key) &&
          values[key] !== null
        ) {
          obj[key] = values[key];
        }

        return obj;
      }, {});

      scheduleDeviceRestart(requestData);
    },
  });

  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Grid item>
          <Tooltip title="Schedule Device Restart">
            <Button
              onClick={() => setOpen(true)}
              color="secondary"
              variant="outlined"
              startIcon={<TimerIcon />}
              style={{ marginRight: "12px" }}
            >
              Schedule Device Restart
            </Button>
          </Tooltip>
        </Grid>
        <Dialog
          open={open}
          onClose={() => setOpen(null)}
          aria-labelledby="form-dialog-title"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            Schedule Device Restart
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  style={{ minWidth: 180 }}
                >
                  <InputLabel id="select-frequency">
                    Select Restart Frequency
                  </InputLabel>
                  <Select
                    labelId="select-frequency"
                    id="scheduled_restart_frequency"
                    value={formik.values.scheduled_restart_frequency}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "scheduled_restart_frequency",
                        e.target.value
                      )
                    }
                  >
                    {scheduleFrequencyOptions.map((frequency, index) => (
                      <MenuItem value={frequency.value} key={index}>
                        {frequency.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {formik.values.scheduled_restart_frequency &&
                formik.values.scheduled_restart_frequency !== "daily" && (
                  <Grid item xs={12} sm={12}>
                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      style={{ minWidth: 180 }}
                    >
                      <InputLabel id="select-day">
                        {`Select Restart  ${
                          formik.values.scheduled_restart_frequency === "weekly"
                            ? "Day"
                            : "Date"
                        }`}
                      </InputLabel>

                      <Select
                        labelId="select-day"
                        id="scheduled_restart_day"
                        value={formik.values.scheduled_restart_day}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "scheduled_restart_day",
                            e.target.value
                          )
                        }
                      >
                        {formik.values.scheduled_restart_frequency === "weekly"
                          ? scheduleWeeklyOptions.map((day, index) => (
                              <MenuItem value={day.value} key={index}>
                                {day.label}
                              </MenuItem>
                            ))
                          : scheduleMonthlyOptions.map((day, index) => (
                              <MenuItem value={day} key={index}>
                                {day}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  style={{ minWidth: 180 }}
                >
                  <TextField
                    id="time"
                    label="Schedule Time (ET)"
                    type="time"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={formik.values.scheduled_restart_time}
                    inputProps={{
                      step: 300,
                    }}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "scheduled_restart_time",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading || formik.isSubmitting}
              onClick={() => formik.submitForm()}
              variant="contained"
              color="primary"
            >
              Schedule Device Restart
            </Button>
            <Button
              disabled={loading || formik.isSubmitting}
              onClick={removeScheduleDeviceRestart}
              variant="contained"
              color="secondary"
            >
              Remove Scheduled Restart
            </Button>
            <Button onClick={() => setOpen(null)} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
