import React, { useState } from "react";

import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const AddLabel = (props) => {
  const {serial_no, device_id, setLoading } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [label, setLabel] = useState(props.label);

  const handleClose = () => {
    setOpen(false);
  };

  const updateDeviceData = () => {
    setLoading(true);
    axios
      .put(`/device/${device_id}/update`, {
        label,
      })
      .then(({ data }) => {
        setMessage({
          type: data.uuid ? "success" : "error",
          text: 'Label Updated Successfully.',
        });
      })
      .catch((error) => {
        setMessage({
          type: "error",
          text: error.response.data.detail,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Typography style={{display:'contents'}}>{label}</Typography>
      <Tooltip title={label === null ? "Add Label" : "Update Label"} >
        <IconButton
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          {label === null ? (
            <AddCircleIcon fontSize="small" />
          ) : (
            <EditIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          {label === null ? "Add Label" : "Update Label"} for {serial_no}{" "}
        </DialogTitle>
        <DialogContent>

          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}
          <Box pb={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="label"
                  label="Label"
                  onChange={(e) => setLabel(e.target.value)}
                  placeholder="Enter Label"
                  value={label}
                />
              </Grid>

      
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={updateDeviceData}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

AddLabel.propTypes = {
  serial_no: PropTypes.string.isRequired,
  device_id: PropTypes.string.isRequired,
  label: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
};

export default AddLabel;
