import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Grid,
  Paper,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import { getLogoPath } from "../partners/utils";
import ServerMaintenance from "../components/ServerMaintenance";
import { useParams } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ResetPassword = () => {
  let history = useHistory();
  const { reset_token } = useParams();
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState();


  const resetPassword = (e) => {
     e.preventDefault();
     if (confirmPassword !== password) {
      setError("New Password and Confirm New Password didn't match.");
      return
     }
    setLoading(true);
    axios
      .post(`/auth/reset-password`, { password, token:reset_token })
      .then((response) => {
        setMessage(response.data.message);
        setError(undefined);
        setPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ServerMaintenance />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {isLoading && <Loader />}
        <Paper className={classes.paper}>
          <Box p={2}>
            <Grid
              container
              className={classes.content}
              direction="column"
              spacing={5}
            >
              <Grid item>
                <img src={getLogoPath()} height="100px" alt="sign in logo" />
              </Grid>
              <Grid item>
                <Typography component="h1" variant="h5">
                  Reset Password
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  To reset your password, please fill in the fields below.
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    {error && <Alert severity="error">{error}</Alert>}
                    {message && <Alert severity="success">{message}</Alert>}
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="new_password"
                      label="New Password"
                      autoFocus
                      type="text"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="confirm_new_password"
                      label="Confirm New Password"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <PasswordChecklist
                      rules={[
                        "minLength",
                        "specialChar",
                        "number",
                        "capital",
                        "match",
                      ]}
                      minLength={8}
                      value={password}
                      valueAgain={confirmPassword}
                      onChange={(isValid) => {}}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={resetPassword}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Back to login page
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default ResetPassword;
