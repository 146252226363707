import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Link,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const ChangeDevicePlan = (props) => {
  const classes = useStyles();
  const [devicePlans, setDevicePlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(undefined);
  const { device_plan, device_uuid, device_name, setLoading } = props;
  const [message, setMessage] = useState(undefined);
  const [open, setOpen] = useState(false);

  const changeDevicePlan = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/change-device-plan`, {
        params: { plan_uuid: selectedPlan },
      })
      .then(({ data }) => {
        if (data.success) {
          setMessage(data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchDevicePlans = () => {
    setLoading(true);
    setMessage(undefined);
    axios
      .get(`/device-plans`)
      .then(({ data }) => {
        setDevicePlans(data);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (device_uuid && (device_uuid !== null) & open) {
      fetchDevicePlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item>
          <Typography>{device_plan}</Typography>
        </Grid>
        <Grid item>
            <Tooltip title="Change Device Plan">
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
                size="small"
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
        </Grid>
      </Grid>

      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth={false}
        maxWidth={"lg"}
      >
        <DialogTitle id="simple-dialog-title">
          Change Plan For {device_name}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Box pt={3}>
              <Alert severity="error"> {error} </Alert>
            </Box>
          )}

          {message && (
            <Box pt={3}>
              <Alert severity="success"> {message} </Alert>
            </Box>
          )}
          {devicePlans && (
            <Box pt={3} pb={3}>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <FormControl
                    className={classes.formControl}
                    style={{ minWidth: 180 }}
                  >
                    <InputLabel id="select-plan">Select Device Plan</InputLabel>
                    <Select
                      labelId="select-plan"
                      id="plan"
                      value={selectedPlan}
                      onChange={(e) => setSelectedPlan(e.target.value)}
                    >
                      {devicePlans.map((plan, index) => (
                        <MenuItem value={plan.uuid} key={index}>
                          {plan.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    onClick={() => {
                      changeDevicePlan();
                    }}
                  >
                    Change Plan
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

ChangeDevicePlan.propTypes = {
  device_plan: PropTypes.string.isRequired,
  device_uuid: PropTypes.string.isRequired,
  device_name: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default ChangeDevicePlan;
