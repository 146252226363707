import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Grid,
  Paper,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";
import { getLogoPath } from "../partners/utils";
import ServerMaintenance from "../components/ServerMaintenance";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ForgotPassword = () => {
  let history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const resetPassword = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`/auth/send-password-reset-link`, { email })
      .then((response) => {
        setMessage(response.data.message);
        setError(undefined);
        setEmail("");
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.detail);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ServerMaintenance />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {isLoading && <Loader />}
        <Paper className={classes.paper}>
          <Box p={2}>
            <Grid
              container
              className={classes.content}
              direction="column"
              spacing={5}
            >
              <Grid item>
                <img src={getLogoPath()} height="100px" alt="sign in logo" />
              </Grid>
              <Grid item>
                <Typography component="h1" variant="h5">
                  Forgot password
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Enter your email and we'll send you a link to reset your
                  password.
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    {error && <Alert severity="error">{error}</Alert>}
                    {message && <Alert severity="success">{message}</Alert>}
                  </Grid>
                  <Grid item>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={resetPassword}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    history.push("/");
                  }}
                >
                  Back to login page
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default ForgotPassword;
