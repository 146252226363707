import React, { useState } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Grid,
  Tooltip,
  DialogActions,
  Typography,
} from "@material-ui/core";
import Loader from "./Loader";
import axios from "axios";
import PublishIcon from "@material-ui/icons/Publish";
import { Alert } from "@material-ui/lab";

const UpdateDeviceFW = (props) => {
  const [loading, setLoading] = useState(false);
  const { deviceData, setMessage, setError } = props;
  const [open, setOpen] = useState(false);
 
  const handleClose = () => {
    setOpen(false);
  };

  const updateDeviceFirmware = () => {
    setLoading(true);
    axios
      .get(`/device/${deviceData.uuid}/update_firmware`)
      .then(({ data }) => {
        if (data.success) {
          setMessage(data.message);
        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
        handleClose()
      });
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <React.Fragment>
        <Grid item>
          <Tooltip title="Update Firmware">
            <Button
              onClick={() => {
                setOpen(true);
              }}
              color="secondary"
              variant="contained"
              startIcon={<PublishIcon />}
              size="small"
            >
              Update
            </Button>
          </Tooltip>
        </Grid>
        <Dialog
          onClose={handleClose}
          open={open}
          aria-labelledby="simple-dialog-title"
          fullWidth={false}
          maxWidth={"lg"}
        >
          <DialogTitle id="simple-dialog-title">
            Update Device {deviceData?.name} Firmware
          </DialogTitle>
          <DialogContent>
            <Typography>
              <Alert severity="warning">
                Please do not power cycle, turn off or unplug your device until
                the update process is fully completed. Interrupting the update
                can cause serious issues with your device.
              </Alert>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={updateDeviceFirmware}
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
};

export default UpdateDeviceFW;
