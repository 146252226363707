import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

const NetScanParsedResults = (props) => {
  const { text } = props;
  const [parsedResult, setParsedResult] = useState([]);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const parseResult = () => {
    const operatorSplitArray = text.split("Operator_Name");
    if (operatorSplitArray.length < 2) {
      return;
    }
    const resultPartArray = operatorSplitArray[1].split("AT+QSCAN");
    let tempCleanedScanResults = [];
    let scanResults = resultPartArray[0];
    // const atScanResult = resultPartArray[1];
    scanResults = scanResults.split("\n").filter((item) => item);
    let counter = 0;
    for (let scanResult of scanResults) {
      let tempArray = scanResult.split(" ").filter((item) => item);
      tempArray.splice(0,0,counter);
      counter+=1;
      tempCleanedScanResults.push(tempArray);
    }

    const cleanedResults = tempCleanedScanResults.map(
      ([id, ref_op, rat, band, signal, op_name]) => ({
        id,
        ref_op,
        rat,
        band,
        signal,
        op_name,
      })
    );

    setParsedResult(cleanedResults);
    console.log(cleanedResults);
  };
   const columns = useRef([
     {
       field: "ref_op",
       headerName: "Ref Op",
       minWidth: 160,
     },
     {
       field: "rat",
       headerName: "RAT",
       minWidth: 120,
     },
     {
       field: "band",
       headerName: "Band",
       minWidth: 120,
     },
     {
       field: "signal",
       headerName: "Signal",
       minWidth: 120,
     },
     {
       field: "op_name",
       headerName: "Operator Name",
       minWidth: 200,
     },
   ]);

  useEffect(() => {
    if (open) {
      parseResult();
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <Typography
        style={{ display: "contents" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {text}
      </Typography>

      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth
        maxWidth={'md'}
      >
        <DialogContent>
          {/* <Typography>{text}</Typography> */}
          <DataGrid
            rows={parsedResult}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            rowCount={parsedResult.length}
          ></DataGrid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

NetScanParsedResults.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NetScanParsedResults;
