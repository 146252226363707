import React, { useState } from "react";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { AppBar, Tab } from "@material-ui/core";
import DevicePortForwarding from "./DevicePortForwarding";
import Header from "./Header";

const TABS = {
  portForward: "portforward",
  dedicatedIps: "dedicatedips",
  bridgeIps: "brideips",
};

const DeviceNetwork = (props) => {
  const [tabValue, setTabValue] = useState(TABS.portForward);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Header />
      <TabContext value={tabValue}>
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Port Forwarding" value={TABS.portForward} />
            <Tab label="Dedicated IPs" value={TABS.dedicatedIps} />
            <Tab label="Bridge IPs" value={TABS.bridgeIpss} />
          </TabList>
        </AppBar>
        <TabPanel value={TABS.portForward}>
          <DevicePortForwarding deviceSerial={props.deviceSerial} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default DeviceNetwork;
