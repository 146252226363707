import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";

import axios from "axios";
import { Alert } from "@material-ui/lab";
import Loader from "../components/Loader";

export default function DeviceSsidModal({ handleClose, device_uuid, open }) {
  const [ssid, setSsid] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [validationError, setValidationError] = useState({});

  const updateDeviceSsid = () => {
    if (!ssid && !password) {
      return;
    }
    const success_message =
      ssid && password ? "SSID and Password" : ssid ? "SSID" : "Password";
    setLoading(true);
    const data = { ssid: ssid, password: password };
    axios
      .post(`/device/${device_uuid}/config`, data)
      .then((response) => {
        setMessage({
          text: `Updated ${success_message} Successfully`,
          type: "success",
        });
      })
      .catch((error) => {
        setMessage({ text: error.response.data.detail, type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrentSsid = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/config`)
      .then((response) => {
        setSsid(response.data.ssid);
        setMessage(null);
      })
      .catch((error) => {
        setMessage({ text: error.response.data.detail, type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const errors = {};
    if (ssid.length > 20) {
      errors.ssid = "SSID cannot be more than 20 characters";
    }
    if (password.length > 40) {
      errors.password = "Password cannot be more than 40 characters";
    }
    if (password.length && password.length < 8) {
      errors.password = "Password cannot be less than 8 characters";
    }

    setValidationError(errors);
  }, [ssid, password]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      {loading && <Loader />}
      <DialogTitle id="form-dialog-title">Update SSID</DialogTitle>
      <DialogContent>
        {message && (
          <Box pt={3} pb={3}>
            <Alert severity={message.type}> {message.text} </Alert>
          </Box>
        )}
        <Button variant="contained" onClick={getCurrentSsid} color="primary">
          Fetch Current SSID
        </Button>
        <Box pb={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormControl
                error={!!(validationError && validationError.ssid)}
                sx={{ width: "100%" }}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  id="ssid"
                  label="SSID Prefix"
                  onChange={(e) => setSsid(e.target.value)}
                  placeholder="SSID Prefix"
                  value={ssid}
                />
                {validationError?.ssid && (
                  <FormHelperText id="ssid-error-text">
                    {validationError.ssid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item>
              <FormControl
                error={!!(validationError && validationError.password)}
                sx={{ width: "100%" }}
              >
                <TextField
                  fullWidth
                  margin="dense"
                  id="password"
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                  value={password}
                />
                {validationError?.password && (
                  <FormHelperText id="password-error-text">
                    {validationError.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={
            loading ||
            validationError?.ssid ||
            validationError?.password ||
            (!ssid && !password)
          }
          onClick={updateDeviceSsid}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
