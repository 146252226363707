import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Box,
  Grid,
  Tooltip,
  Checkbox,
  Button,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import Layout from "../layouts/Layout";
import { useParams } from "react-router-dom";
import DateAndTime from "../components/DateAndTime";
import SaveIcon from "@material-ui/icons/Save";

const ResellerPortGroups = (props) => {
  const { reseller_uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [clientName, setClientName] = useState();
  const [portGroups, setPortGroups] = useState([]);
  const [sortModel, setSortModel] = useState();
  const [rowsCount, setRowsCount] = useState();
  
  const [pageSize, setPageSize] = useState(100);
  const [message,setMessage] = useState(undefined);
  const columns = useRef([
    {
      field: "name",
      headerName: "Port Group Name",
      flex: 1,
    },
    {
      field: "assigned",
      headerName: "Is Assigned ?",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={`Port Group is  ${
            params.row.is_assigned ? "Assigned" : "Not Assigned"
          }`}
        >
          <Checkbox
            checked={params.row.is_assigned}
            onChange={(e) => {
              params.row.is_assigned = e.target.checked;
              // updateScrapeDataUsage(params.row.uuid, {
              //   scrape_data_usage: e.target.checked,
              // });
            }}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </Tooltip>
      ),
    },
    {
      field: "last_updated_at",
      headerName: "Last Updated At",
      flex: 1,
      renderCell: (params) => (
        <DateAndTime dateAndTime={params.row.last_updated_at} />
      ),
    },
  ]);

  const fetchClientDetails = () => {
    setLoading(true);
    axios
      .get(`/reseller/${reseller_uuid}`)
      .then(({ data }) => {
        setClientName(data.name);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchClientUsers = () => {
    setLoading(true);
    axios
      .get(`/reseller/${reseller_uuid}/assigned-port-groups`)
      .then(({ data }) => {
        setPortGroups(data);
        setRowsCount(data.length);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };
const updateData = ()=>{
  let portGroupIds = [];
  for(const port_group of portGroups){
    if(port_group.is_assigned){
      portGroupIds.push(port_group.uuid);
    }
  }
   setLoading(true);
   axios
     .post(`/reseller/${reseller_uuid}/assign-port-group`, portGroupIds)
     .then(({ data }) => {
       setMessage(data.message);
     })
     .catch((error) => {
       setError(error.response.data.detail);
     })
     .finally(() => {
       setLoading(false);
     });
}
  useEffect(() => {
    fetchClientDetails();
    fetchClientUsers();
    // eslint-disable-next-line
  }, [reseller_uuid]);

  return (
    <Layout loading={loading} error={error} successMessage={message}>
      <Box mb={2} pt={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" align="left">
              Manage Port Groups for {clientName}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={updateData}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>

      {portGroups.length !== 0 && (
        <Box pt={4}>
          <DataGrid
            rows={portGroups}
            columns={columns.current}
            disableSelectionOnClick
            autoHeight
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            pageSize={pageSize}
            rowCount={rowsCount}
            initialState={{
              pagination: {
                page: 1,
              },
            }}
            getRowId={(row) => row.uuid}
            onPageSizeChange={setPageSize}
          />
        </Box>
      )}
    </Layout>
  );
};
export default ResellerPortGroups;
