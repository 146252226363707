import React, { useState } from "react";

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Checkbox,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import PropTypes from "prop-types";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import { isSuperAdmin } from "../auth/roles";

const EditReseller = (props) => {
  const { resellerInfo, setLoading, callOnSuccess } = props;
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [name, setName] = useState(resellerInfo.name);
  const [bwCustomerId, setBwCustomerId] = useState(resellerInfo.bw_customer_id);
  const [allowActivateDeactivate, setAllowActivateDeactivate] = useState(
    resellerInfo?.allow_activate_deactivate
  );
  const [allowPlanChange, setAllowPlanChange] = useState(
    resellerInfo?.allow_plan_change
  );
  const [deviceWebhook, setDeviceWebhook] = useState(
    resellerInfo?.device_webhook
  );

  const handleClose = () => {
    setOpen(false);
  };

  const updateReseller = () => {
    const updateData = {
      name,
      bw_customer_id: bwCustomerId,
      allow_activate_deactivate: allowActivateDeactivate,
      allow_plan_change: allowPlanChange,
      device_webhook: deviceWebhook,
    };

    setLoading(true);

    axios
      .put(`/reseller/${resellerInfo.uuid}`, updateData)
      .then((response) => {
        if (response.status === 200) {
          setMessage({
            type: "success",
            text: "Reseller Updated Successfully.",
          });
          callOnSuccess();
        }
      })
      .catch((error) => {
        setMessage({
          type: "error",
          text: error.response.data.detail,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <Tooltip title="Update Details">
        <IconButton
          size="small"
          variant="outlined"
          color="primary"
          onClick={() => {
            setOpen(true);
          }}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Update Details for {resellerInfo.name}
        </DialogTitle>
        <DialogContent>
          {message && (
            <Box pt={3} pb={3}>
              <Alert severity={message.type}> {message.text} </Alert>
            </Box>
          )}
          <Box pb={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  autoFocus
                  margin="dense"
                  id="full_name"
                  label="Reseller Name"
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Reseller Name"
                  value={name}
                />
              </Grid>

              {isSuperAdmin() && (
                <>
                  <Grid item>
                    <TextField
                      fullWidth
                      autoFocus
                      margin="dense"
                      id="bw_customer_id"
                      label="Britewireless Customer Id"
                      onChange={(e) => setBwCustomerId(e.target.value)}
                      placeholder="Enter Britewireless Customer Id"
                      value={bwCustomerId}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      autoFocus
                      margin="dense"
                      id="device_webhook"
                      label="Device Assignment Webhook"
                      onChange={(e) => setDeviceWebhook(e.target.value)}
                      placeholder="Enter Webhook URL"
                      value={deviceWebhook}
                    />
                  </Grid>
                </>
              )}

              <Grid item>
                <Tooltip title="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allowActivateDeactivate}
                        onChange={(e) => {
                          setAllowActivateDeactivate(e.target.checked);
                        }}
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Allow Reseller to Activate & De-activate the Device
                      </Typography>
                    }
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={allowPlanChange}
                        onChange={(e) => {
                          setAllowPlanChange(e.target.checked);
                        }}
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Allow Reseller to Change plan on Device
                      </Typography>
                    }
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={updateReseller} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

EditReseller.propTypes = {
  resellerInfo: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  callOnSuccess: PropTypes.func.isRequired,
};

export default EditReseller;
