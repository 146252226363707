import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Layout from "../layouts/Layout";
import Loader from "../components/Loader";
import {
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
}));
export default function ProfileContainer() {
  const [userData, setUserData] = useState();
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    axios.get("/auth/me").then((response) => {
      setUserData(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Layout>
        <Box pt={2} pb={3}>
          <Typography variant="h3">Profile</Typography>
        </Box>
        {userData && (
          <TableContainer component={Paper}>
            <Table aria-label="profile table">
              <TableBody>
                <TableRow>
                  <TableCell className={classes.head}>Full Name</TableCell>
                  <TableCell>{userData.full_name}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.head}>Email</TableCell>
                  <TableCell>{userData.email}</TableCell>
                </TableRow>

      

              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Layout>
    </React.Fragment>
  );
}
