import React from "react";
import { Chip } from "@material-ui/core";
import { GREEN, RED, YELLOW, GREY } from "../utils/constants";
import PropTypes from "prop-types";

const PassStatus = (props) => {
  const { status, status_formatted } = props;
  return (
    <Chip
      label={status_formatted}
      size="small"
      style={{
        fontWeight: 500,
        background:
          status === 0 || status === 1 || status === 8
            ? RED
            : status === 2 || status === 9
            ? YELLOW
            : status === 3 || status === 4 || status === 5 || status === 10 || status  === 11
            ? GREEN
            : status === 6 || status === 6 
            ? GREY
            : "",
        color: status === "Failed",
      }}
    />
  );
};

PassStatus.propTypes = {
  status: PropTypes.number.isRequired,
  status_formatted: PropTypes.string.isRequired
};

export default PassStatus;
