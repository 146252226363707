import React, { useState, useEffect } from "react";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Link,
  Grid,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import NetworkCheckIcon from "@material-ui/icons/NetworkCheck";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));
const StartNetDiag = (props) => {
  const classes = useStyles();
  const [portGroups, setPortGroups] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [error, setError] = useState(undefined);
  const { setLoading } = props;
  const [message, setMessage] = useState(undefined);
  const { device_uuid } = useParams();
  const [open, setOpen] = useState(false);

  const initiateNetDiag = () => {
    setLoading(true);
    axios
      .get(`/device/${device_uuid}/start-net-diag`, {
        params: { port_group_uuid: selectedPlan },
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchPortGroups = () => {
    setLoading(true);
    setMessage(undefined);
    axios
      .get(`/port-groups`)
      .then(({ data }) => {
        const filteredPortGroups = [];
        for(let portGroup of data){
          if(portGroup.name.startsWith('RT')){
            filteredPortGroups.push(portGroup);
          }
        }
        setPortGroups(filteredPortGroups);
      })
      .catch((error) => {
        setError(error.response.data.detail);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (device_uuid && (device_uuid !== null) & open) {
      fetchPortGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        color="primary"
        variant="outlined"
        startIcon={<NetworkCheckIcon />}
      >
        Start Net Diagnosis
      </Button>

      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth={false}
        maxWidth={"lg"}
      >
        <DialogTitle id="simple-dialog-title">Start Net Diagnosis</DialogTitle>
        <DialogContent>
          {error && (
            <Box pt={3}>
              <Alert severity="error"> {error} </Alert>
            </Box>
          )}

          {message && (
            <Box pt={3}>
              <Alert severity="success"> {message} </Alert>
            </Box>
          )}
          {portGroups && (
            <Box pt={3} pb={3}>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <FormControl
                    className={classes.formControl}
                    style={{ minWidth: 180 }}
                  >
                    <InputLabel id="select-plan">Select Port Group</InputLabel>
                    <Select
                      labelId="select-plan"
                      id="plan"
                      value={selectedPlan}
                      onChange={(e) => setSelectedPlan(e.target.value)}
                    >
                      {portGroups.map((plan, index) => (
                        <MenuItem value={plan.uuid} key={index}>
                          {plan.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            onClick={initiateNetDiag}
          >
            Start
          </Button>

          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

StartNetDiag.propTypes = {
  setLoading: PropTypes.func.isRequired,
};

export default StartNetDiag;
