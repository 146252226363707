import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";

const InfoInPopup = (props) => {
  const { text } = props;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Typography
        style={{ display: "contents" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {text}
      </Typography>

      <Dialog
        onClose={handleClose}
        open={open}
        aria-labelledby="simple-dialog-title"
        fullWidth={false}
        maxWidth={"lg"}
      >
        <DialogContent>
          <Typography
    
          >
            {text}
          </Typography>

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>

        </DialogActions>
      </Dialog>
    </>
  );
};

InfoInPopup.propTypes = {
  text: PropTypes.string.isRequired,
};

export default InfoInPopup;
